import React from "react";
import { UtwData } from "../../types/utw";
import appContext from "../../appContext";

interface Props {
  track: UtwData;
  albumS: string;
}

const Track: React.FC<Props> = ({ track, albumS }) => {
  const {
    dispatch,
    state: { playingTrackId }
  } = React.useContext(appContext);

  const handlePlay = React.useCallback(() => {
    dispatch({
      type: "set",
      payload: {
        playingTrackId: track.id,
        playingAlbumS: albumS
      }
    });
  }, [dispatch, track, albumS]);

  const handleStop = React.useCallback(() => {
    dispatch({
      type: "set",
      payload: {
        playingTrackId: undefined
      }
    });
  }, [dispatch]);

  const isPlaying = playingTrackId === track.id;

  return isPlaying ? (
    <li>
      <strong>{track.nazwa}</strong>
      <button onClick={handleStop}>stop</button>
    </li>
  ) : (
    <li>
      <span>{track.nazwa}</span>
      <button onClick={handlePlay}>play</button>
    </li>
  );
};

export default Track;
