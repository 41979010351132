import React from "react";
import appContext from "../../appContext";
import api from "../../api";
import { WydData, schemaWydData } from "../../types/wyd";
import { UtwData } from "../../types/utw";
import Audio from "./Audio";
import RequestWrapper from "../RequestWrapper/RequestWrapper";

interface Props {
  data: WydData;
}

const Player: React.FC<Props> = ({ data }) => {
  const [trackList, setTrackList] = React.useState<UtwData[]>([]);
  const [trackData, setTrackData] = React.useState<UtwData | null>(null);
  const {
    state: { playingTrackId },
    dispatch
  } = React.useContext(appContext);

  React.useEffect(() => {
    const newTrackList =
      data.nos.find(({ rodzaj }) => rodzaj === "audio")?.utw || [];

    setTrackList(newTrackList);
  }, [data]);

  React.useEffect(() => {
    const newTrackData =
      trackList.find(({ id }) => id === playingTrackId) || null;

    setTrackData(newTrackData);
  }, [trackList, playingTrackId]);

  const fastForward = React.useCallback(() => {
    const currentIndex = trackList.findIndex(({ id }) => id === playingTrackId);
    const nextIndex = (currentIndex + 1) % trackList.length;
    const nextId = trackList[nextIndex].id;

    dispatch({ type: "set", payload: { playingTrackId: nextId } });
  }, [trackList, playingTrackId, dispatch]);

  if (!trackData) {
    return null;
  }

  return <Audio data={trackData} onEnded={fastForward} />;
};

const WrappedPlayer: React.FC = () => {
  const {
    state: { playingAlbumS }
  } = React.useContext(appContext);

  if (!playingAlbumS) {
    return null;
  }

  return (
    <RequestWrapper<WydData>
      request={api.get(`//api.karrot.pl/wyd/${playingAlbumS}`, schemaWydData)}
    >
      {data => <Player data={data} />}
    </RequestWrapper>
  );
};

export default WrappedPlayer;
