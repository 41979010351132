import { Primitive, Nullable, Options } from "validate-typescript";
import { UtwData, schemaUtwData } from "./utw";
import { YesNo, Dzial, schemaYesNo } from "./common";

type NosRodzaj = "audio" | "cd" | "cd2" | "cd3" | "winyl" | "winyl2";
const schemaNosRodzaj = Options([
  "audio",
  "cd",
  "cd2",
  "cd3",
  "winyl",
  "winyl2"
]) as NosRodzaj;

export interface NosData {
  id: string;
  s_wyd: string;
  s_obrazka: null | string;
  nazwa: string;
  nazwa_b: null | string;
  nazwa_ang: null | string;
  rodzaj: NosRodzaj;
  notka: string;
  notka_ang: null | string;
  nr_kat: null | string;
  wydawca: null | string;
  cena_pln: string;
  cena_eur: string;
  cena_usd: string;
  waga: string;
  czy_dostepne: YesNo;
  czy_rabat: string;
  stary_kod: null | string;
  dzial: Dzial;
  utw: UtwData[];
}

export const schemaNosData: NosData = {
  id: Primitive(String),
  s_wyd: Primitive(String),
  s_obrazka: Nullable(Primitive(String)),
  nazwa: Primitive(String),
  nazwa_b: Nullable(Primitive(String)),
  nazwa_ang: Nullable(Primitive(String)),
  rodzaj: schemaNosRodzaj,
  notka: Primitive(String),
  notka_ang: Nullable(Primitive(String)),
  nr_kat: Nullable(Primitive(String)),
  wydawca: Nullable(Primitive(String)),
  cena_pln: Primitive(String),
  cena_eur: Primitive(String),
  cena_usd: Primitive(String),
  waga: Primitive(String),
  czy_dostepne: schemaYesNo,
  czy_rabat: Primitive(String),
  stary_kod: Nullable(Primitive(String)),
  dzial: "nos",
  utw: [schemaUtwData]
};
