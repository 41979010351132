import React from "react";
import { UtwData } from "../../types/utw";
import Track from "../Track/Track";

interface Props {
  list: UtwData[];
  albumS: string;
}

const TrackList: React.FC<Props> = ({ list, albumS }) => {
  return (
    <ol>
      {list.map((track, index) => (
        <Track key={track.id} track={track} albumS={albumS} />
      ))}
    </ol>
  );
};

export default TrackList;
