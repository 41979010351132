import React from "react";
import { fold } from "@devexperts/remote-data-ts";
import useRemoteData from "../../hooks/useRemoteData";
import { AppError } from "../../errors/types";

interface Props<T> {
  request: Promise<any>;
  children: (data: T) => React.ReactNode;
}

function RequestWrapper<T>({ request, children }: Props<T>): JSX.Element {
  const [remoteData, remoteRequest] = useRemoteData<AppError, T>();

  React.useEffect(() => {
    remoteRequest(request);
  }, [remoteRequest, request]);

  return <>{fold<AppError, T, React.ReactNode>(
    () => "idle",
    () => "pending",
    failure => failure,
    data => children(data)
  )(remoteData)}</>;
}

export default RequestWrapper;
