import { initial, pending, success, failure, RemoteData } from "@devexperts/remote-data-ts";
import useSafeState from "./useSafeState";
import React from "react";

type RemoteRequest<U> = (asyncFunction: Promise<U>) => Promise<U | void>

const useRemoteData = <E = any, T = any>(): [RemoteData<E, T>, RemoteRequest<T>] => {
  const [remoteData, setRemoteData] = useSafeState<RemoteData<E, T>>(initial);

  const remoteRequest:RemoteRequest<T> = React.useCallback(asyncFunction => {
    setRemoteData(pending);

    return asyncFunction
      .then(result => {
        setRemoteData(success(result));
        return result;
      })
      .catch(error => {
        setRemoteData(failure(error.message));
      });
  }, [setRemoteData]
  );

  return [remoteData, remoteRequest];
};

export default useRemoteData;
