import React from "react";
import Album from "./components/Album/Album";
import Player from "./components/Player/Player";
import Login from "./components/Login/Login";
import AppState from "./AppState";

const App: React.FC = () => {
  return (
    <div className="App">
      <AppState>
        <Login />
        <Album s="momenty" />
        <Album s="inadibusu" />
        <Player />
      </AppState>
    </div>
  );
};

export default App;
