import React from "react";
import { WydData } from "../../types/wyd";
import bb from "../../utils/bb";
interface Props {
  data: WydData;
}

const Header: React.FC<Props> = ({ data: { nazwa, notka } }) => {
  return (
    <>
      <h2>{nazwa}</h2>
      <p dangerouslySetInnerHTML={{ __html: bb(notka) }} />
    </>
  );
};

export default Header;
