import { Primitive } from "validate-typescript";

export interface ApiData<T> {
  status: number;
  status_message: string;
  data: T;
}

export const schemaApiData = <T>(schemaT: T): ApiData<T> => ({
  status: Primitive(Number),
  status_message: Primitive(String),
  data: schemaT
});
