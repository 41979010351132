const deepSearch = (
  object: any,
  key: string,
  predicate: (key: string, value: any) => boolean
): any => {
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true)
    return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    const nextObject = object[Object.keys(object)[i]];
    if (nextObject && typeof nextObject === "object") {
      let o = deepSearch(nextObject, key, predicate);
      if (o != null) return o;
    }
  }
  return null;
};

export default deepSearch;
