import { Primitive, Options, Nullable } from "validate-typescript";
import { ImpMiniData, schemaImpMiniData } from "./imp";
import { NosData, schemaNosData } from "./nos";
import { YesNo, Dzial, schemaYesNo } from "./common";

type Rodzaj = "LP" | "EP" | "S";
const schemaRodzaj = Options(["LP" , "EP" , "S"]) as Rodzaj

export interface WydData {
  id: string;
  s: string;
  custom_imp_nazwa: null | string;
  nazwa: string;
  nazwa_d: null | string;
  nazwa_ang: null | string;
  nr_kat: string;
  rok: string;
  wydawca: null | string;
  rodzaj: Rodzaj;
  notka: string;
  notka_ang: null | string;
  s_dotyczy: null | string;
  czy_dostepne: YesNo;
  wyswietlenia: string;
  dzial: Dzial;
  imp: ImpMiniData[];
  nos: NosData[];
}

export const schemaWydData: WydData = {
  id: Primitive(String),
  s: Primitive(String),
  custom_imp_nazwa: Nullable(Primitive(String)),
  nazwa: Primitive(String),
  nazwa_d: Nullable(Primitive(String)),
  nazwa_ang: Nullable(Primitive(String)),
  nr_kat: Primitive(String),
  rok: Primitive(String),
  wydawca: Nullable(Primitive(String)),
  rodzaj: schemaRodzaj,
  notka: Primitive(String),
  notka_ang: Nullable(Primitive(String)),
  s_dotyczy: Nullable(Primitive(String)),
  czy_dostepne: schemaYesNo,
  wyswietlenia: Primitive(String),
  dzial: "wyd",
  imp: [schemaImpMiniData],
  nos: [schemaNosData]
};
