import { Primitive } from "validate-typescript";
import { Dzial } from "./common";

export interface UzyMiniData {
  login: string;
  email: string;
  imie: string;
  dzial: Dzial;
}

export const schemaUzyMiniData: UzyMiniData = {
  login: Primitive(String),
  email: Primitive(String),
  imie: Primitive(String),
  dzial: "uzy"
};
