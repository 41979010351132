import React from "react";
import { Formik, Form, Field } from "formik";
import api from "../../api";
import { schemaUzyMiniData, UzyMiniData } from "../../types/uzy";
import { fold } from "@devexperts/remote-data-ts";
import useRemoteData from "../../hooks/useRemoteData";
import { AppError } from "../../errors/types";

interface Values {
  login: string;
  password: string;
}

interface loginFormProps {
  onLogin: (values: Values) => void;
}

const LoginForm: React.FC<loginFormProps> = ({ onLogin }) => (
  <Formik
    onSubmit={onLogin}
    initialValues={{ login: "", password: "" } as Values}
  >
    {() => (
      <Form>
        <Field type="text" name="login" />
        <Field type="password" name="password" />
        <button type="submit">submit</button>
      </Form>
    )}
  </Formik>
);

interface userInfoProps {
  onLogout: () => void;
  data: UzyMiniData;
}

const UserInfo: React.FC<userInfoProps> = ({ onLogout, data }) => (
  <>
    <h3>{data.login}</h3>
    <ul>
      {Object.keys(data).map(key => (
        <li key={key}>
          <span>{key}: </span>
          <strong>{data[key as keyof UzyMiniData]}</strong>
        </li>
      ))}
    </ul>
    <button onClick={onLogout}>logout</button>
  </>
);

const LoginRequestWrapper: React.FC = () => {
  const [remoteData, remoteRequest] = useRemoteData<AppError, UzyMiniData>();

  React.useEffect(() => {
    remoteRequest(api.get(`//api.karrot.pl/user`, schemaUzyMiniData));
  }, [remoteRequest]);

  const handleLogin = React.useCallback(
    (values: Values) => {
      remoteRequest(
        api.post(`//api.karrot.pl/user/login`, values, schemaUzyMiniData)
      );
    },
    [remoteRequest]
  );
  const handleLogout = React.useCallback(() => {
    remoteRequest(
      api.post(`//api.karrot.pl/user/logout`, null, schemaUzyMiniData)
    );
  }, [remoteRequest]);

  return (
    <>
      {fold<AppError, UzyMiniData, React.ReactNode>(
        () => "idle",
        () => "pending",
        failure => <LoginForm onLogin={handleLogin} />,
        data => <UserInfo onLogout={handleLogout} data={data} />
      )(remoteData)}
    </>
  );
};

export default LoginRequestWrapper;
