import AppContext, { appReducer, initialAppData } from "./appContext";
import React, { useReducer } from "react";

const AppState: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialAppData);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
