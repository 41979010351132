import { Primitive, Nullable } from "validate-typescript";
import { ImpMiniData, schemaImpMiniData } from "./imp";
import { YesNo, Dzial, schemaYesNo } from "./common";

export interface UtwData {
  id: string;
  nazwa: string;
  nazwa_ang: null | string;
  dlugosc: string;
  czy_dostepne: YesNo;
  darmowe_mp3: YesNo;
  start_prev: null | string;
  stop_prev: null | string;
  dzial: Dzial;
  imp: ImpMiniData[];
}

export const schemaUtwData: UtwData = {
  id: Primitive(String),
  nazwa: Primitive(String),
  nazwa_ang: Nullable(Primitive(String)),
  dlugosc: Primitive(String),
  czy_dostepne: schemaYesNo,
  darmowe_mp3: schemaYesNo,
  start_prev: Nullable(Primitive(String)),
  stop_prev: Nullable(Primitive(String)),
  dzial: "utw",
  imp: [schemaImpMiniData]
};
