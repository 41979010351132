const normalizeUrl = (url: string) => {
  if (url.slice(0, 7) === "http://") {
    return url;
  }

  if (url.slice(0, 8) === "https://") {
    return url;
  }

  return `//${url}`;
};

const tagReplace = (string: string, bbTag: string, htmlTag: string) => {
  const openReg = new RegExp(`\\[${bbTag}]`, "g");
  const closeReg = new RegExp(`\\[\\/${bbTag}]`, "g");

  return string
    .replace(openReg, `<${htmlTag} className="BB__${bbTag}">`)
    .replace(closeReg, `</${htmlTag}>`);
};

const wrapLine = (line: string, tag: string) =>
  `<${tag} class="BB__${tag}">${line}</${tag}>`;

const wrapLines = (string: string, tag: string) =>
  string
    .split("\r\n")
    .map(line => line.trim())
    .filter(line => line.length)
    .map(line => (line[0] !== "<" ? wrapLine(line, tag) : line))
    .join("");

const parseUnsortedLst = (string: string) => {
  const reg = /\[lista]((.|\r|\n)*?)\[\/lista]/g;
  let result = string;

  while (true) {
    const found = reg.exec(string);

    if (found === null) {
      break;
    }

    result = result.replace(
      found[0],
      `<ul class="BB__ul">${wrapLines(found[1], "li")}</ul>`
    );
  }

  return result;
};

const parseImg = (string: string) => {
  const reg = /\[img](.*?)\[\/img]/g;
  let result = string;

  while (true) {
    const found = reg.exec(string);

    if (found === null) {
      break;
    }

    result = result.replace(
      found[0],
      `<img class="BB__img" src="${normalizeUrl(found[1])}" />`
    );
  }

  return result;
};

const parseUrl = (string: string) => {
  const reg = /\[url](.*?)\[\/url]/g;
  let result = string;

  while (true) {
    const found = reg.exec(string);

    if (found === null) {
      break;
    }

    result = result.replace(
      found[0],
      `<a class="BB__a" href="${normalizeUrl(found[1])}">${found[1]}</a>`
    );
  }

  return result;
};

export default (bbString: string) => {
  let result = bbString;

  result = parseUnsortedLst(result);
  result = wrapLines(result, "p");
  result = tagReplace(result, "i", "i");
  result = parseImg(result);
  result = parseUrl(result);

  return result;
};
