import React from 'react';

const useSafeState = <T>(initialState: T): [T, (value: T) => void] => {
  const [state, unsafeSetState] = React.useState<T>(initialState);

  const unmounted = React.useRef(false);

  React.useEffect(
    () => () => {
      unmounted.current = true;
    },
    []
  );

  const setState = React.useCallback((value: T) => {
    if (unmounted.current) {
      return;
    }
    unsafeSetState(value);
  }, []);

  return [state, setState];
};

export default useSafeState;