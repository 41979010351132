import axios from "axios";
import { ApiData, schemaApiData } from "./types";
import { validate } from "validate-typescript";
import parseError from "../errors/parseError";

export const get = <T>(url: string, responseSchema: T): Promise<T> =>
  axios
    .get<ApiData<T>>(url, { withCredentials: true })
    .then(
      response => validate(schemaApiData(responseSchema), response.data).data
    )
    .catch(error => {
      throw new Error(parseError(error));
    });

export const post = <T, U = any>(
  url: string,
  data: U,
  responseSchema: T
): Promise<T> =>
  axios
    .post<ApiData<T>>(url, data, { withCredentials: true })
    .then(
      response => validate(schemaApiData(responseSchema), response.data).data
    )
    .catch(error => {
      throw new Error(parseError(error));
    });

export default { get, post };
