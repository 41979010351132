import React from "react";

export interface Action {
  type: "set" | "reset";
  payload: Partial<AppData>;
}

export interface AppData {
  playingAlbumS?: string;
  playingTrackId?: string;
}

export const initialAppData: AppData = {
  playingAlbumS: undefined,
  playingTrackId: undefined,
};

export const appReducer = (
  state: AppData,
  { type, payload }: Action
): AppData => {
  switch (type) {
    case "set":
      return { ...state, ...payload };
    case "reset":
    default:
      return initialAppData;
  }
};

interface AppContext {
  state: AppData;
  dispatch: React.Dispatch<Action>;
}

export default React.createContext<AppContext>({
  state: initialAppData,
  dispatch: () => null
});
