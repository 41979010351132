import deepSearch from "../utils/deepSearch";

export const parseValidationError = (error: any): any => {
  const firstChildError = deepSearch(error, "child_error", () => true);
  const { property, value, child_error } = firstChildError;

  const message = `${property}: ${value} -  "${child_error.value}" ${child_error.details}`;

  console.error(message);
  return message;
};

export const parseAxiosError = (error: any): any => {
  const { status, statusText } = error.response;

  return `${status}: ${statusText}`;
};

export const parseError = (error: any): string => {
  if (error.message === "Validate TypeScript") {
    return parseValidationError(error);
  }

  if (error.response) {
    return parseAxiosError(error)
  }

  return error.message;
};

export default parseError;
