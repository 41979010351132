import { Primitive } from "validate-typescript";
import { Dzial } from "./common";

export interface ImpMiniData {
  id: string;
  s: string;
  nazwa: string;
  dzial: Dzial;
}

export const schemaImpMiniData: ImpMiniData = {
  id: Primitive(String),
  s: Primitive(String),
  nazwa: Primitive(String),
  dzial: "imp"
};
