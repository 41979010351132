import React from "react";
import { UtwData } from "../../types/utw";

interface Props {
  data: UtwData;
  onEnded: () => void;
}

const Audio: React.FC<Props> = ({ data, onEnded }) => {
  const playerRef = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (!playerRef.current) {
      return;
    }

    playerRef.current.volume = 0.75;
  }, []);

  const onEvent = (event: Event) => {
    console.log(event.type);
  };

  const onLoaded = async (event: Event) => {
    console.log(event.type);
    try {
      await playerRef.current?.play();
    } catch (e) {
      console.log(e);
    }
  };

  const handlePlay = () => {
    playerRef.current?.play();
  };

  React.useEffect(() => {
    console.log(playerRef.current);
    if (!playerRef.current) {
      return;
    }

    const player = playerRef.current;
    player.addEventListener("loadeddata", onLoaded);
    player.addEventListener("loadstart", onEvent);
    player.addEventListener("loadeddata", onEvent);
    player.addEventListener("error", onEvent);
    player.addEventListener("abort", onEvent);
    player.addEventListener("playing", onEvent);
    player.addEventListener("progress", onEvent);
    player.addEventListener("ended", onEnded);
    return () => {
      player.removeEventListener("loadeddata", onLoaded);
      player.removeEventListener("loadstart", onEvent);
      player.removeEventListener("loadeddata", onEvent);
      player.removeEventListener("error", onEvent);
      player.removeEventListener("abort", onEvent);
      player.removeEventListener("playing", onEvent);
      player.removeEventListener("progress", onEvent);
      player.removeEventListener("ended", onEnded);
    };
  }, [data, onEnded]);

  return (
    <>
      <h4>Now playing: </h4>
      <p>{data.nazwa}</p>
      <p>{data.dlugosc}</p>
      <audio
        ref={playerRef}
        src={`//api.karrot.pl/audio/${data.id}?.mp3`}
        controls
      />
      <button onClick={handlePlay}>play</button>
    </>
  );
};

export default Audio;
