import React from "react";
import api from "../../api";
import { WydData, schemaWydData } from "../../types/wyd";
import Header from "./Header";
import TrackList from "../TrackList/TrackList";
import RequestWrapper from "../RequestWrapper/RequestWrapper";

interface Props {
  s: string;
}

const Album: React.FC<Props> = ({ s }) => (
  <RequestWrapper<WydData>
    request={api.get(`//api.karrot.pl/wyd/${s}`, schemaWydData)}
  >
    {data => (
      <>
        <Header data={data} />
        <TrackList list={data.nos[0].utw} albumS={s} />
      </>
    )}
  </RequestWrapper>
);

export default Album;
